import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { sub } from "date-fns";
import { useEffect, useState } from "react";

import { useContractId } from "../../../context/ContractIDContext";
import { usePrimaryAPIClient } from "../../../context/PrimaryAPIClient";
import {
	fundsFilter,
	fundsFilterType,
	fundsFormat,
	fundsFormattedType,
	fundsSort,
	initialValues,
} from "./chartCardFunctions";
import ChartCardLayout from "./ChartCardLayout";

export default function LifeChartCard(): JSX.Element {
	const [funds, setFunds] = useState<fundsFormattedType>([]);
	const [formValues, setFormValues] = useState<fundsFilterType>({
		dateTo: new Date(),
		dateFrom: sub(new Date(), { years: 1 }),
		funds: {},
	});
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { contractsLifeApi } = usePrimaryAPIClient();
	const id = useContractId();
	const ai = useAppInsightsContext();

	useEffect(() => {
		async function loadData() {
			const rawFunds = await contractsLifeApi.getLifeFundsGet({
				contractId: id,
			});

			if (rawFunds.length === 0) {
				ai.trackEvent(
					{ name: "No DPS/PP funds data" },
					{ contractId: id, isDPS: false }
				);
			}

			const funds = fundsFormat(fundsSort(rawFunds));
			setFunds(funds);
			setFormValues(initialValues(funds));
		}

		void loadData();
	}, []);

	return (
		<ChartCardLayout
			funds={funds}
			data={fundsFilter(funds, formValues)}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			setFormValues={setFormValues}
		/>
	);
}
