import { Stack } from "@mui/material";

import { GetLifeDetailResponse } from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import { NumberToCZK } from "../../../../../functions";
import { useLifeDetail } from "../../../../../queryHooks";
import cs from "../../../../../translations/cs.json";
import Attention from "../../../../AttentionBlock";
import { CellSkeleton } from "../../../../Table/TableWithSkeleton";
import { BodyTextM, HeadingL, HeadingXL } from "../../../../Typography";

export const SurrenderValue = (): JSX.Element => {
	const id = useContractId();
	const result = useLifeDetail<GetLifeDetailResponse>({ id: id });
	const surrValue = result.data?.surrValue;

	return (
		<>
			<Stack direction="column" sx={{ justifyContent: "flex-start" }}>
				<HeadingL mb={3}>{cs.life.investing.surrender.header}</HeadingL>
				<BodyTextM mb={1}>{cs.life.investing.surrender.description}</BodyTextM>
				<Stack
					direction="row"
					sx={{ justifyContent: "space-between", alignItems: "center" }}
				>
					<BodyTextM strong light>
						{cs.life.investing.surrender.value}
					</BodyTextM>
					<Stack
						direction="row"
						sx={{
							flexWrap: "nowrap",
							justifyContent: "flex-end",
							alignItems: "flex-start",
						}}
					>
						<HeadingXL mb={0}>
							{result.isLoading ? (
								<CellSkeleton />
							) : surrValue !== undefined ? (
								NumberToCZK(surrValue)
							) : (
								"-"
							)}
						</HeadingXL>
					</Stack>
				</Stack>
				<Attention severity="info">
					{cs.life.investing.surrender.infmation}
				</Attention>
			</Stack>
		</>
	);
};
