import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Files = (props: SvgIconProps): JSX.Element => {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M8.0332 14.1538H4.0942C3.8178 14.1538 3.5942 14.3774 3.5942 14.6538C3.5942 14.9302 3.8178 15.1538 4.0942 15.1538H8.0332C8.3096 15.1538 8.5332 14.9302 8.5332 14.6538C8.5332 14.3774 8.3096 14.1538 8.0332 14.1538Z"
				fill="#414141"
			/>
			<path
				d="M7.4644 20.0884H1V1H13.9287V9.1426C13.9287 9.419 14.1523 9.6426 14.4287 9.6426C14.7051 9.6426 14.9287 9.419 14.9287 9.1426V0.5C14.9287 0.2236 14.7051 0 14.4287 0H0.5C0.2236 0 0 0.2236 0 0.5V20.5884C0 20.8648 0.2236 21.0884 0.5 21.0884H7.4644C7.7408 21.0884 7.9644 20.8648 7.9644 20.5884C7.9644 20.312 7.7407 20.0884 7.4644 20.0884Z"
				fill="#414141"
			/>
			<path
				d="M10.834 9.3232H4.0942C3.8178 9.3232 3.5942 9.5468 3.5942 9.8232C3.5942 10.0996 3.8178 10.3232 4.0942 10.3232H10.8339C11.1103 10.3232 11.3339 10.0996 11.3339 9.8232C11.3339 9.5468 11.1104 9.3232 10.834 9.3232Z"
				fill="#414141"
			/>
			<path
				d="M10.834 4.4932H4.0942C3.8178 4.4932 3.5942 4.7168 3.5942 4.9932C3.5942 5.2696 3.8178 5.4932 4.0942 5.4932H10.8339C11.1103 5.4932 11.3339 5.2696 11.3339 4.9932C11.3339 4.7168 11.1104 4.4932 10.834 4.4932Z"
				fill="#414141"
			/>
			<path
				d="M16.914 16.1904C22.412 16.1904 23.7724 14.4248 23.9091 14.2236C23.9657 14.1406 23.996 14.0429 23.996 13.9424C23.996 13.459 23.7948 12.9961 23.4452 12.6724C22.6034 11.8946 20.7645 10.9673 16.7821 10.9673C12.0604 10.9673 10.5165 12.2632 10.0165 13.0357C9.8959 13.2217 9.8319 13.4415 9.8319 13.67C9.8319 14.0118 9.9779 14.336 10.2328 14.5577C10.9316 15.167 12.7289 16.1904 16.914 16.1904ZM10.8559 13.5791C11.1005 13.2012 12.2514 11.9673 16.7822 11.9673C20.6338 11.9673 22.1845 12.8692 22.7656 13.4063C22.8701 13.503 22.9424 13.629 22.9756 13.7686C22.5811 14.127 21.0615 15.1905 16.9141 15.1905C12.9112 15.1905 11.3682 14.2208 10.8902 13.8038C10.8526 13.7716 10.8321 13.7237 10.8321 13.67L10.8559 13.5791Z"
				fill="#414141"
			/>
			<path
				d="M23.0888 21.4619C23.0781 21.4766 21.8779 23 16.914 23C11.9501 23 10.7504 21.4766 10.7451 21.4707C10.5898 21.2422 10.2798 21.1826 10.0508 21.3389C9.8223 21.4942 9.7632 21.8047 9.919 22.0332C10.0556 22.2344 11.416 24 16.914 24C22.412 24 23.7724 22.2344 23.9091 22.0332C24.0624 21.8086 24.0048 21.5078 23.7831 21.3496C23.5615 21.1934 23.2538 21.2422 23.0888 21.4619Z"
				fill="#414141"
			/>
			<path
				d="M23.0937 18.7402C23.083 18.7558 21.8828 20.2802 16.9189 20.2802C11.955 20.2802 10.7548 18.7558 10.75 18.7499C10.5938 18.5214 10.2832 18.4628 10.0552 18.6181C9.8267 18.7734 9.7676 19.0839 9.9229 19.3124C10.0601 19.5136 11.4209 21.2802 16.919 21.2802C22.4161 21.2802 23.7774 19.5136 23.9141 19.3124C24.0674 19.0878 24.0098 18.787 23.7881 18.6288C23.5663 18.4727 23.2587 18.5225 23.0937 18.7402Z"
				fill="#414141"
			/>
			<path
				d="M16.914 18.8584C22.412 18.8584 23.7724 17.0918 23.9091 16.8906C24.0624 16.666 24.0038 16.3652 23.7831 16.207C23.5614 16.0498 23.2528 16.1006 23.0888 16.3193C23.0771 16.3339 21.8779 17.8584 16.914 17.8584C11.9501 17.8584 10.7504 16.334 10.7455 16.3281C10.5893 16.0996 10.2797 16.041 10.0507 16.1963C9.8222 16.3516 9.7631 16.6621 9.9184 16.8906C10.0556 17.0918 11.416 18.8584 16.914 18.8584Z"
				fill="#414141"
			/>
		</SvgIcon>
	);
};

export default Files;
