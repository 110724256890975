import { PageProps } from "gatsby";

import { ProductType } from "../../../models";
import { SEO } from "../../components/SEO";
import { LifeInsuranceDetail } from "../../components/ZivotniPojisteni/LifePage/Root";
import { ContractIDProvider } from "../../context/ContractIDContext";
import { ContractTypeProvider } from "../../context/ContractTypeContext";
import withProtection from "../../highOrderComponents/withProtection";

const LifeInsuranceDetailPage = (pageProps: PageProps): JSX.Element => {
	return (
		<ContractIDProvider pageProps={pageProps}>
			<ContractTypeProvider contractType={ProductType.Cl}>
				<LifeInsuranceDetail />
			</ContractTypeProvider>
		</ContractIDProvider>
	);
};

export default withProtection(LifeInsuranceDetailPage);

export const Head = (): JSX.Element => (
	<SEO title="Životko">
		<meta name="robots" content="noindex" />
	</SEO>
);
