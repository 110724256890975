import { Stack } from "@mui/material";

import { GetLifeDetailResponse } from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import { useLifeDetail } from "../../../../../queryHooks";
import FormCoins from "../../../../Icons/FormCoins";
import { BodyTextM } from "../../../../Typography";
import TopRow from "./TopRow";

export const StrategyValueWithData = (): JSX.Element => {
	const id = useContractId();

	const result = useLifeDetail<GetLifeDetailResponse>({ id: id });

	if (result.isSuccess) {
		if (result.data.investmentStrategy) {
			return (
				<TopRow>
					<Stack sx={{ alignItems: "center" }} direction="row">
						<FormCoins sx={{ width: "2.4rem", height: "2.4rem" }} />
						<Stack
							direction="row"
							sx={{ flexGrow: 1, justifyContent: { md: "space-between" } }}
						>
							<BodyTextM ml={4}>Vaše zvolená strategie:&nbsp;</BodyTextM>
							<BodyTextM strong>{result.data.investmentStrategy}</BodyTextM>
						</Stack>
					</Stack>
				</TopRow>
			);
		}
	}

	return <></>;
};
