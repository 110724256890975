import { IconButton } from "@mui/material";
import { Stack } from "@mui/material";
import { useState } from "react";

import cs from "../../../../../translations/cs.json";
import SimpleDrawer from "../../../../Drawer/SimpleDrawer";
import InfoIcon from "../../../../Icons/Info";
import { TriggerButton } from "../../../../Link";
import { BodyTextM } from "../../../../Typography";

function CommonInsuranceAllocationTitle() {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			{cs.life.investing.alocatedRatio.extraordinary}
			<IconButton onClick={() => setIsOpen(true)}>
				<InfoIcon />
			</IconButton>
			<SimpleDrawer
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				open={isOpen}
			>
				<Stack spacing={5}>
					<BodyTextM>
						{cs.life.investing.alocatedRatio.extraordinaryDescription}
					</BodyTextM>
					<TriggerButton
						onClick={() => setIsOpen(false)}
						sx={{ alignSelf: "flex-end" }}
					>
						Rozumím
					</TriggerButton>
				</Stack>
			</SimpleDrawer>
		</>
	);
}

export default CommonInsuranceAllocationTitle;
