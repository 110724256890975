import { Grid, Stack } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

import { useContractId } from "../../../context/ContractIDContext";
import LifeChartCard from "../../Charts/ChartCard/LifeChartCard";
import HelpBox from "../../InfoBox/HelpBox";
import { HeadingL } from "../../Typography";
import AccountValue from "./components/AccountValue/index";
import FundsWithData from "./components/Investing/Fond";
import { StrategyValueWithData } from "./components/Investing/StrategyValueWithData";
import { SurrenderValue } from "./components/Investing/SurrenderValue";
import TopRow from "./components/Investing/TopRow";
import { LifeTabWrapper } from "./TabWrapper";

function Investing(): JSX.Element {
	const id = useContractId();

	const { translations } =
		useStaticQuery<Queries.InvestingTranslationQuery>(graphql`
			query InvestingTranslation {
				translations: file(name: { eq: "cs" }) {
					childTranslationsJson {
						life {
							investing {
								fondsHeading
								fondsGraphHeading
								fondsGraphDescription
							}
						}
					}
				}
			}
		`);

	return (
		<LifeTabWrapper>
			<Grid container spacing={{ xxs: 4, md: 6 }}>
				<Grid item xxs={12} md={6}>
					<TopRow>
						<AccountValue />
					</TopRow>
				</Grid>
				<Grid item xxs={12} md={6}>
					<StrategyValueWithData contractId={id} />
				</Grid>
			</Grid>
			<Stack>
				<HeadingL>
					{translations.childTranslationsJson.life.investing.fondsHeading}
				</HeadingL>
				<FundsWithData contractId={id} />
			</Stack>
			<LifeChartCard />
			{process.env.GATSBY_FEATURE_IS_LIFE_SURRENDER_VALUE_ENABLED ===
				"true" && <SurrenderValue />}
			<HelpBox />
		</LifeTabWrapper>
	);
}

export default Investing;
