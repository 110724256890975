import Paper from "@mui/material/Paper";
import { ReactNode } from "react";

interface TopRowProps {
	children: ReactNode;
}

function TopRow({ children }: TopRowProps): JSX.Element {
	return <Paper sx={{ py: 5, px: 4 }}>{children}</Paper>;
}

export default TopRow;
