import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useIsDesktop from "desktop/useIsDesktop";
import { useState } from "react";

import { GetLifeDetailResponse } from "../../../../../../models";
import { useContractId } from "../../../../../context/ContractIDContext";
import {
	NumberExtraDigits,
	NumberToCZK,
	NumberToCZKExtraDigits,
} from "../../../../../functions";
import { useLifeDetail } from "../../../../../queryHooks";
import cs from "../../../../../translations/cs.json";
import SwipeableInformationList from "../../../../InformationList/Swipeable";
import { ScrollSnapper } from "../../../../ScrollSnapper";
import { CellSkeleton } from "../../../../Table/TableWithSkeleton";
import CommonInsuranceAllocationTitle from "./CommonInsuranceAllocationTitle";

const FundsWithData = () => {
	const id = useContractId();
	const result = useLifeDetail<GetLifeDetailResponse>({ id: id });
	const [fundIndex, setFundIndex] = useState(0);

	const { funds } = result.data || {
		funds: [
			{
				depositRatio: undefined,
				depositTopUpRatio: undefined,
				puAmount: undefined,
				puValue: undefined,
				valuePUStart: undefined,
				title: undefined,
				totalAmount: undefined,
			},
		],
	};

	if (funds.length === 0) return <>{cs.life.investing.noFundsFound}</>;

	const isDesktop = useIsDesktop();

	if (isDesktop)
		return (
			<>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell sx={{ minWidth: "220px" }}>
									{cs.life.investing.fundName}:
								</TableCell>
								{funds.map((fund, i) => (
									<TableCell key={i} sx={{ minWidth: "200px" }}>
										{result.isLoading ? <CellSkeleton /> : fund.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>{cs.life.investing.currentFondValue}:</TableCell>
								{funds.map((fund, i) => (
									<TableCell key={i}>
										{result.isLoading ? (
											<CellSkeleton />
										) : fund.totalAmount !== undefined ? (
											NumberToCZK(fund.totalAmount)
										) : (
											"-"
										)}
									</TableCell>
								))}
							</TableRow>
							<TableRow>
								<TableCell>{cs.life.investing.unitsAmount}:</TableCell>
								{funds.map((fund, i) => (
									<TableCell key={i}>
										{result.isLoading ? (
											<CellSkeleton />
										) : fund.puAmount !== undefined ? (
											NumberExtraDigits(fund.puAmount, 5)
										) : (
											"-"
										)}
									</TableCell>
								))}
							</TableRow>
							{process.env.GATSBY_FEATURE_IS_LIFE_SURRENDER_VALUE_ENABLED ===
								"true" &&
								funds.some(
									(fund) =>
										typeof fund.valuePUStart === "number" &&
										fund.valuePUStart > 0
								) && (
									<TableRow>
										<TableCell>{cs.life.investing.unitsAmountStart}:</TableCell>
										{funds.map((fund, i) => (
											<TableCell key={i}>
												{result.isLoading ? (
													<CellSkeleton />
												) : fund.valuePUStart !== undefined ? (
													NumberExtraDigits(fund.valuePUStart, 5)
												) : (
													"-"
												)}
											</TableCell>
										))}
									</TableRow>
								)}
							<TableRow>
								<TableCell>{cs.life.investing.unitPrice}:</TableCell>
								{funds.map((fund, i) => (
									<TableCell key={i}>
										{result.isLoading ? (
											<CellSkeleton />
										) : fund.puValue !== undefined ? (
											NumberToCZKExtraDigits(fund.puValue, 5)
										) : (
											"-"
										)}
									</TableCell>
								))}
							</TableRow>
							<TableRow>
								<TableCell>
									{cs.life.investing.alocatedRatio.regular}:
								</TableCell>
								{funds.map((fund, i) => (
									<TableCell key={i}>
										{result.isLoading ? (
											<CellSkeleton />
										) : fund.depositRatio ? (
											`${fund.depositRatio} %`
										) : (
											"-"
										)}
									</TableCell>
								))}
							</TableRow>
							<TableRow
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell>
									<CommonInsuranceAllocationTitle />
								</TableCell>
								{funds.map((fund, i) => (
									<TableCell key={i}>
										{result.isLoading ? (
											<CellSkeleton />
										) : fund.depositTopUpRatio ? (
											`${fund.depositTopUpRatio} %`
										) : (
											"-"
										)}
									</TableCell>
								))}
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	else
		return (
			<ScrollSnapper
				sx={{ mx: { md: 0, xxs: -4 } }}
				index={fundIndex}
				onIndexChange={setFundIndex}
			>
				{funds.map((fund) => (
					<SwipeableInformationList
						isLoading={result.isLoading}
						key={fund.title}
						title={fund.title}
						information={[
							{
								title: cs.life.investing.currentFondValue,
								description:
									fund.totalAmount !== undefined
										? NumberToCZK(fund.totalAmount)
										: "-",
							},
							{
								title: cs.life.investing.unitsAmount,
								description:
									fund.puAmount !== undefined
										? NumberExtraDigits(fund.puAmount, 5)
										: "-",
							},
							process.env.GATSBY_FEATURE_IS_LIFE_SURRENDER_VALUE_ENABLED ===
								"true" &&
							funds.some(
								(fund) =>
									typeof fund.valuePUStart === "number" && fund.valuePUStart > 0
							)
								? {
										title: cs.life.investing.unitsAmountStart,
										description:
											fund.puAmount !== undefined
												? NumberExtraDigits(fund.valuePUStart, 5)
												: "-",
								  }
								: null,
							{
								title: cs.life.investing.unitPrice,
								description:
									fund.puValue !== undefined
										? NumberToCZKExtraDigits(fund.puValue, 5)
										: "-",
								highlighted: true,
							},
							{
								title: cs.life.investing.alocatedRatio.regular,
								description: fund.depositRatio ? `${fund.depositRatio} %` : "-",
							},
							{
								title: <CommonInsuranceAllocationTitle />,
								description: fund.depositTopUpRatio
									? `${fund.depositTopUpRatio} %`
									: "-",
							},
						].filter((el) => el !== null)}
					/>
				))}
			</ScrollSnapper>
		);
};

export default FundsWithData;
